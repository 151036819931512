.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.custom-range::-webkit-slider-thumb {
  background: white;
  border-radius: 50%;
}

.custom-range::-moz-range-thumb {
  background: white;
  border-radius: 50%;
}

.custom-range::-ms-thumb {
  background: white;
  border-radius: 50%;
}

.custom-range::-webkit-slider-runnable-track {
  background: gray;
  border-radius: 3px;
}

.custom-range::-moz-range-track {
  background: gray;
  border-radius: 3px;
}

.custom-range::-ms-track {
  background: gray;
  border-radius: 3px;
}

.offer-modal {
  max-width: 70%;
}

.offer-modal-footer {
  justify-content: center;
}