.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #343a40;
  background-color: #343a40;
  border-color: #fff #fff #343a40;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #343a40;
  background-color: #343a40;
  border-color: #343a40 #343a40 #fff;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  height: var(--navTabHeight);
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.qty .count {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  padding: 0 2px;
  min-width: 35px;
  text-align: center;
}
.qty .plus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 30px;
  height: 30px;
  font: 30px/1 Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
}
.qty .minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 30px;
  height: 30px;
  font: 30px/1 Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
}
div {
  text-align: center;
}
.minus:hover {
  background-color: #828585 !important;
}
.plus:hover {
  background-color: #828585 !important;
}
/*Prevent text selection*/
span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
input {
  border: 0;
  width: 2%;
}
nput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:disabled {
  background-color: white;
}

.or-bar {
  height: 15px;
  width: 100%;
  border-bottom: solid 2px white;
  text-align: center;
  margin: 2em 0;
}
.or-bar span {
  background: #343a40;
  position: relative;
  top: 0;
  padding: 0 20px;
  line-height: 30px;
  font: 20px/1 Arial, sans-serif;
  font-weight: 700;
}
